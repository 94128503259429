<script lang="ts" setup>
const {
  public: { BwpSiteId, BwpWidgetId,
  },
} = useRuntimeConfig()
</script>

<template>
  <div
    id="amzn-bwp-cart"
    :data-site-id="BwpSiteId"
    :data-widget-id="BwpWidgetId"
  />
</template>

<style scoped>
</style>
